@keyframes marqueeLeft {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

@keyframes marqueeRight {
    from { transform: translateX(0); }
    to { transform: translateX(50%); }
}

.block-marquee {

    & + &,
    & + .block-bleed {
        margin-top: -60px;
        @screen m {
            margin-top: -80px;
        }
        @screen l {
            margin-top: -100px;
        }
    }

    &__text {
        will-change: transform;
        animation: marqueeLeft 120s linear infinite;
    }

    &--right &__text {
        animation-name: marqueeRight;
    }
}
