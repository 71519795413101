.page-header {

    &__inner:not(.fixed) {
        padding: 0 !important;
    }

    #menu:target &__menu[hidden] {
        display: block !important;
        html.js:not(.js-error) &[hidden] {
            display: none !important;
        }
    }

    .menu-open &__inner {
        transform: none !important;
    }

    .menu-panel {
        &__bg {
            width: calc(100% + theme('outerMarginsMap.l'));
            @media (min-width: calc(theme('maxContentWidth') + (theme('outerMarginsMap.l') * 2))) {
                width: calc(100% + (((100vw - var(--scrollbar-width, 0px)) - theme('maxContentWidth')) / 2));
            }
        }
    }

    body.menu-open & [data-toggle] {
        color: theme('colors.black') !important;
    }

}
