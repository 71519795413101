.lazyload,
.lazyloading {
    opacity: 0;
    //transform: translateZ(0);
}

.lazyloaded {
    transition: opacity 0.6s theme('transitionTimingFunction.out-quad');
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.02, 1.02) translate(0, 1vh);
    transform-origin: center bottom;
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button, a {
    touch-action: manipulation;
    border-radius: 0;

    &[disabled] {
        cursor: default;
    }
}

button {
    appearance: none;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

select {
    appearance: none;
}

html.reduce-motion {
    *, *:before, *:after {
        animation: none !important;
        transition: none !important;
    }
}

::-moz-selection {
    background: theme('colors.purple.light');
    color: theme('colors.black');
}

::selection {
    background: rgb(210, 175, 249, 0.99); // Same as "purple light"
    color: theme('colors.black');
}

body.bg-purple {
    ::-moz-selection {
        background: theme('colors.black');
        color: theme('colors.white');
    }

    ::selection {
        background: rgb(0, 0, 0, 0.99);
        color: theme('colors.white');
    }
}

*[class*="hover:underline"] {
    text-underline-offset: 1px;
    @supports (text-decoration-color: transparent) {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.3s;
        &:hover {
            text-decoration-color: black;
        // Can't use currentColor because Safari 15 isn't able to transition to it
        }
    }
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

.image-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.44) 0%, rgba(196, 196, 196, 0) 100%);
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

:root .block-bleed:last-child {
    margin-bottom: 0 !important;
}

img {
    pointer-events: none;
}

strong, b {
    font-weight: normal;
}

i, em {
    font-family: 'Agipo Regular Italic';
    font-style: italic;
}

.font-signifier,
.quote {
    &, * {
        font-family: "Signifier", serif !important;
    }
}

html.wf-active .fixascender::before,
html.wf-inactive .fixascender::before {
    content: "";
    display: block;
    padding-top: 0.13em;
}

html.js:not(.js-error):not(.is-preview) [data-reveal] {
    opacity: 0;
}

.link {
    background-image: linear-gradient(currentColor, currentColor), linear-gradient(theme('colors.purple.light'), theme('colors.purple.light'));
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 0% 1px, 100% 1px;
    body[class*="bg-"]:not([class*=" bg-white"]):not([class*=" bg-grey"]) & {
        background-image: linear-gradient(currentColor, currentColor), linear-gradient(currentColor, currentColor);
    }
}
