@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .subtitle { // TODO I think this is a <h2> - maybe rename .heading or something
        @apply text-18 leading-22 m:text-21 m:leading-25 l:text-28 l:leading-34 xl:text-33 xl:leading-37 uppercase;
    }

    .intro {
        @apply text-24 leading-29 m:text-29 m:leading-39 l:text-45 l:leading-62 xl:text-56 xl:leading-72;
    }

    .body {
        @apply text-16 leading-24 m:text-20 m:leading-29 l:text-23 l:leading-32 xl:text-28 xl:leading-39;
    }

    .caption {
        @apply text-13 leading-16 m:text-15 m:leading-20 l:text-17 l:leading-23 xl:text-20 xl:leading-28;
        > * {
            max-width: 62ch;
        }
    }

    .minicaption {
        @apply text-13 leading-16 l:text-14 l:leading-17 xl:text-16 xl:leading-19;
    }

    .quote {
        @apply font-signifier font-light text-28 leading-36 m:text-39 m:leading-55 l:text-46 l:leading-62 xl:text-56 xl:leading-74;
    }

    .btn {
        @apply text-black border-black bg-transparent;
        &:not([aria-disabled="true"]):not([disabled]) {
            @apply hover:opacity-50 group-hover:opacity-50;
        }
        &[disabled],
        &[aria-disabled="true"] {
            @apply opacity-20 cursor-default;
        }
    }

    .pad-top {
        @apply pt-120 m:pt-120 l:pt-220;
    }

}
