@keyframes line {
    0% {
        background-size: 0% 2px;
    }
    50% {
        background-size: 0% 2px;
    }
    75% {
        background-size: 100% 2px;
    }
    100% {
        background-size: 100% 2px;
    }
}

.annotation {
    &__btn {
        background-image: linear-gradient(theme('colors.purple.light'), theme('colors.purple.light'));
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 0% 2px;

        animation: line;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        padding: 2px 0 0 0;

        body[class*="bg-"]:not([class*=" bg-white"]) & {
            background-image: linear-gradient(black, black);
        }

        > span:first-child {
            background-image: linear-gradient(theme('colors.purple.light'), theme('colors.purple.light'));
            background-position: 0% calc(100% - 0px);
            background-repeat: no-repeat;
            background-size: 100% 0%;
            transition: background-size 0.3s;
            padding: 0 0.1em 0 0;

            body[class*="bg-"]:not([class*=" bg-white"]) & {
                background-image: linear-gradient(black, black);
            }
        }

        &[aria-expanded="true"] > span:first-child {
            background-size: 100% 100%;
            body[class*="bg-"]:not([class*=" bg-white"]) & {
                color: white;
            }
        }
    }
}
