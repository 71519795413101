// JS-only CSS overrides for the TextN3D block
html.js .textn3d {

    height: 300vh;

    margin-bottom: -80px;

    @screen m {
        margin-bottom: -120px;
    }

    @screen ls {
        height: 400vh;
    }

    &__txt {

        min-height: 100vh;
        position: sticky;
        top: 110px;

        @screen m {
            top: 140px;
        }

        @screen l {
            top: 160px;
        }

        @screen xl {
            top: 180px;
        }

        @screen pt {
            &[data-text="bottom"] {
                margin-top: -50vh;
            }
        }

    }

    & + * {
        margin-top: 0 !important;
    }
}
