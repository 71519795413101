@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */

    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .min-fullheight {
        min-height: 100vh;
        @supports (--custom:property) {
            min-height: calc(var(--vh, 1vh) * 100);
        }
    }

    .hit {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%; top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .no-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .bleed {
        padding-left: 0;
        padding-right: 0;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        vertical-align: bottom;
    }

    .no-ellipsis {
        text-overflow: unset;
        overflow: unset;
        max-width: unset;
        white-space: unset;
        vertical-align: unset;
    }

    .ratio-ls {
        width: 100%;
        height: 0;
        padding-bottom: calc((2/3) * 100%);
    }

    .ratio-pt {
        width: 100%;
        height: 0;
        padding-bottom: calc((4/3) * 100%);
    }

    .ratio-sq {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .scrollable-x {
        overflow: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

    .scrollpad {
        padding-right: var(--scrollbar-width, 0px);
    }

    .scrollbar {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
            box-shadow: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: theme('colors.grey.DEFAULT');
            outline: none !important;
        }
    }

}
