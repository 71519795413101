.carousel {

    &__image {

        &-figure {
            width: calc(((100vw - (80px + var(--scrollbar-width, 16px))) / var(--max-ratio)) * var(--ratio));

            @screen m {
                width: calc((60vw / var(--max-ratio)) * var(--ratio));
                max-width: calc(70vmin * var(--ratio));
                .carousel--ls & {
                    width: calc((80vw / var(--max-ratio)) * var(--ratio));
                }
                .carousel--sq & {
                    width: calc((40vw / var(--max-ratio)) * var(--ratio));
                }
            }

            @screen l {
                width: calc((40vw / var(--max-ratio)) * var(--ratio));
                .carousel--ls & {
                    width: calc((60vw / var(--max-ratio)) * var(--ratio));
                }
                .carousel--sq & {
                    width: calc((30vw / var(--max-ratio)) * var(--ratio));
                }
            }

        }

        &-img {
            height: calc((100vw - (80px + var(--scrollbar-width, 16px))) / var(--max-ratio));
            @screen m {
                height: calc(60vw / var(--max-ratio));
                max-height: 70vmin;
                .carousel--ls & {
                    height: calc(80vw / var(--max-ratio));
                }
                .carousel--sq & {
                    height: calc(40vw / var(--max-ratio));
                }
            }
            @screen l {
                height: calc(40vw / var(--max-ratio));
                .carousel--ls & {
                    height: calc(60vw / var(--max-ratio));
                }
                .carousel--sq & {
                    height: calc(30vw / var(--max-ratio));
                }
            }
        }

    }
}
