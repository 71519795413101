@keyframes scroll {
    from { transform: translateX(0); }
    to { transform: translateX(var(--translate, -25%)); }
}

.scroller {
    > * {
        animation: scroll 60s infinite linear;
        @supports (--custom:property) {
            animation-duration: var(--duration, 60s);
        }
    }

    & + & {
        @apply -mt-40;
    }
}
